<template>
  <div class="InstrumentIDManage content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="仪器编号">
              <el-input
                placeholder="输入仪器编号搜索"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择">
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button type="primary" size="small" @click="addInstrument"
            >新增检测结果</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column label="仪器编号" prop="InstrumentID"></el-table-column>
        <el-table-column
          label="仪器名称"
          prop="InstrumentName"
        ></el-table-column>
        <el-table-column label="标准等级" prop="StandardID"></el-table-column>
        <el-table-column label="对应开始值" prop="BeginValue"></el-table-column>
        <el-table-column label="对应结束值" prop="EndValue"></el-table-column>
        <el-table-column label="状态" prop="ConditionName"></el-table-column>
        <el-table-column
          label="结果评语和建议"
          prop="Proposal"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="备注"
          prop="Remark"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="有效性" prop="Active">
          <template slot-scope="scope">{{
            scope.row.Active ? "有效" : "无效"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="deleter(scope.row)"
              v-if="isDelete"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      destroy-on-close
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="仪器编号" prop="InstrumentID">
                <el-select v-model="ruleForm.InstrumentID" placeholder="请选择" class="width_220">
                  <el-option
                    v-for="item in Instrumentlist"
                    :key="item.InstrumentID"
                    :label="item.Name"
                    :value="item.InstrumentID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标准等级" prop="StandardID">
                <el-input
                  :disabled="dialogtype == 2"
                  clearable
                  v-model="ruleForm.StandardID"
                  placeholder="请填写值状态名称"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="值状态" prop="ConditionID">
                <el-select
                  clearable
                  filterable
                  v-model="ruleForm.ConditionID"
                  placeholder="请选择值状态"
                  class="width_220"
                >
                  <el-option
                    :label="item.Name"
                    :value="item.ConditionID"
                    v-for="item in statusList"
                    :key="item.ConditionID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起始值" prop="BeginValue">
                <el-input
                  clearable
                  v-model="ruleForm.BeginValue"
                  placeholder="请输入起始值"
                  class="width_220"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="结束值" prop="EndValue">
                <el-input
                  clearable
                  v-model="ruleForm.EndValue"
                  placeholder="请输入结束值"
                  class="width_220"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="结果评语和建议" prop="Proposal">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入建议"
                  v-model="ruleForm.Proposal"
                  >></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="Remark">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入备注"
                  v-model="ruleForm.Remark"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="dialogtype == 2">
            <el-col :span="12">
              <el-form-item label="有效性" prop="Active">
                <el-radio v-model="ruleForm.Active" :label="true">是</el-radio>
                <el-radio v-model="ruleForm.Active" :label="false">否</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Instrument/ResultTotalScore";
import permission from "@/components/js/permission.js";
export default {
  name: "InstrumentIDManage",
  /**  引入的组件  */
  components: {},
  beforeCreate(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "检测结果总分");
    });
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      Active: null,
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      isDelete: true,
      dialogtype: 1,
      dialogVisible: false,
      searchName: "",
      statusList: [], //值状态
      Instrumentlist: [], //仪器编号
      tableData: [
        {
          Active: true,
          Remark: "备注",
          Proposal: "评论建议",
          ConditionName: "一般",
          ConditionID: "A1",
          EndValue: "5.5",
          BeginValue: "1.22",
          StandardID: "2",
          InstrumentName: "美容仪",
          InstrumentID: "MRY01",
        },
      ],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        InstrumentID: "",
        StandardID: "",
        ConditionID: "",
        BeginValue: "",
        EndValue: "",
        Proposal: "",
        Remark: "",
      },
      rules: {
        InstrumentID: [
          { required: true, message: "请输入仪器编号", trigger: "blur" },
        ],
        BeginValue: [
          { required: true, message: "请输入起始值", trigger: "blur" },
        ],
        EndValue: [
          { required: true, message: "请输入结束值", trigger: "blur" },
        ],
        StandardID: [
          { required: true, message: "请输入状态", trigger: "change" },
        ],
        ConditionID: [
          { required: true, message: "请选择有效性", trigger: "blur" },
        ],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    handleSearch() {
      var that = this;
      var parames = {
        Active: that.Active,
        InstrumentID: "",
        PageNum: that.paginations.page,
      };
      API.skinStandardSumConfig(parames).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    formatStatus(row) {
      var text = "";
      switch (row.status) {
        case 0:
          text = "很差";
          break;
        case 1:
          text = "较差";
          break;
        case 2:
          text = "一般";
          break;
        case 3:
          text = "良好";
          break;
        case 4:
          text = "较好";
          break;
        case 5:
          text = "完美";
          break;
        default:
          text = "一般";
          break;
      }
      return text;
    },
    addInstrument() {
      this.dialogtype = 1;
      this.getCondition();
      this.skinInstrument();
      this.dialogVisible = true;
    },
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editor(row) {
      const {
        InstrumentID,
        StandardID,
        ConditionID,
        BeginValue,
        EndValue,
        Proposal,
        Remark,
        BarColo,
        Active,
      } = row;
      this.ruleForm = {
        InstrumentID,
        StandardID,
        ConditionID,
        BeginValue,
        EndValue,
        Active,
        Proposal,
        Remark,
        BarColo,
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
    },
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    closeDialog() {
      this.ruleForm = {
        InstrumentID: "",
        StandardID: "",
        ConditionID: "",
        BeginValue: "",
        EndValue: "",
        Proposal: "",
        Remark: "",
      };
      this.dialogVisible = false;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createScore();
          } else {
            that.updateScore();
          }
        }
      });
    },
    createScore() {
      var that = this;
      API.create(that.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 获取值状态
    getCondition() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinCondition(params).then((res) => {
        if (res.StateCode == 200) {
          that.statusList = res.List;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    skinInstrument() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinInstrument(params).then((res) => {
        if (res.StateCode == 200) {
          that.Instrumentlist = res.Data;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    updateScore() {
      var that = this;
      API.update(that.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已修改!");
          that.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "检测结果总分"
    );
    this.getCondition();
    this.skinInstrument();
    this.handleSearch();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
